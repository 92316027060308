<template>
	

	<div class="p-grid mt-5">
		<div class="grid">
			<div class="col-12">
				<div class="card">
					<DataTable :value="aramaSonuclariData" selectionMode="single" :rowHover="true" :scrollable="true" scrollHeight="500px" editMode="cell" @cell-edit-complete="aramaSonuclariOnCellEditComplete" @cell-edit-init="aramaSonuclariOnCellEditInit" responsiveLayout="scroll">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<template #header>
							<div class="flex justify-content-between">
								<div style="width: 30%;">
									<div class="p-inputgroup">
										<InputText v-model="aranacakText" type="search" placeholder="Parça Kodu / Yedek Parça / Üretici" v-on:keyup.enter="onClickListele"/>
										<Button label="Listele" icon="pi pi-search" iconPos="right" class="p-button-secondary" @click="onClickListele" />
										<Button label="Temizle" iconPos="right" class="p-button-danger ml-3" @click="onClickTemizle" />
									</div>
								</div>
								<div>
									<Button label="Yeni Yedek Parça" icon="pi pi-plus" iconPos="right" class="p-button-warning m-1" @click="onClickYeniYedekParca" />
									<Button label="Excel'den Yedek Parça Yükle" icon="pi pi-upload" iconPos="right" class="p-button-success m-1" @click="displayExcelImport = true" />
									<Button label="Servis Raporundan Yedek Parça Yükle" icon="pi pi-upload" iconPos="right" class="p-button-info m-1" @click="displayServisRaporuImport = true" />
								</div>
								
							</div>
						</template>
						<Column field="yedekParcaKodu" header="Yedek Parça Kodu" style="flex: 0 0 11rem">
							<template #body="slotProps">
								<span v-if="slotProps.data.bulundu == false" class="durum-badge bulunamadi">{{ slotProps.data.yedekParcaKodu }} </span>
								<span v-else >{{ slotProps.data.yedekParcaKodu }} </span>
							</template>
						</Column>
						<Column field="yedekParcaAdi" header="Yedek Parça Adı">
							<template #body="slotProps">
								<span v-if="slotProps.data.bulundu == false" class="durum-badge bulunamadi">Bulunamadı!</span>
								<span v-else >{{ slotProps.data.yedekParcaAdi }} </span>
							</template>
						</Column>
						<Column field="uretici" header="Üretici" style="flex: 0 0 10rem">
							<template #body="slotProps">
								<span v-if="slotProps.data.bulundu == false" class="durum-badge bulunamadi">{{ slotProps.data.uretici }} </span>
								<span v-else >{{ slotProps.data.uretici }} </span>
							</template>
						</Column>
						<Column field="teslimSuresi" header="Teslim Süresi" style="flex: 0 0 15rem">
							<template #editor="{ data, field }">
								<InputText v-model="data[field]" type="search" inputStyle="width: 40px" @input="data[field] = $event.target.value.toUpperCase()" />
							</template>
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ slotProps.data.teslimSuresi }} </span>
								<span v-else >{{ slotProps.data.teslimSuresi }} </span>
							</template>
						</Column>
						<Column field="adet" header="Miktar" style="flex: 0 0 7rem; justify-content: center;">
							<template #editor="{ data, field }">
								<InputNumber v-model="data[field]" mode="decimal" showButtons :min="0" :max="1000" inputStyle="width: 40px" />
							</template>
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ formatNumber(slotProps.data.adet) }} </span>
								<span v-else >{{ formatNumber(slotProps.data.adet) }} </span>
							</template>
						</Column>
						<Column field="birimFiyat" header="Birim Fiyat" style="flex: 0 0 7rem; justify-content: right;">
							<template #editor="{ data, field }">
								<InputNumber v-model="data[field]" mode="decimal" :minFractionDigits="2" inputStyle="width: 80px" />
							</template>
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ formatNumber(slotProps.data.birimFiyat) }} {{ getCurrency2(slotProps.data.paraBirimiName) }}</span>
								<span v-else >{{ formatNumber(slotProps.data.birimFiyat) }} {{ getCurrency2(slotProps.data.paraBirimiName) }}</span>
							</template>
						</Column>
						<Column field="paraBirimiName" header="Para Birimi" bodyStyle="text-align: center" style="flex: 0 0 7rem; justify-content: center;">
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ slotProps.data.paraBirimiName }} </span>
								<span v-else >{{ slotProps.data.paraBirimiName }} </span>
							</template>
						</Column>
						<Column field="toplamFiyat" header="Sipariş Fiyatı" bodyStyle="text-align: right" style="flex: 0 0 7rem; justify-content: right;" >
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ formatNumber(slotProps.data.toplamFiyat) }}  {{ getCurrency2(slotProps.data.paraBirimiName) }}</span>
								<span v-else >{{ formatNumber(slotProps.data.toplamFiyat) }}  {{ getCurrency2(slotProps.data.paraBirimiName) }}</span>
							</template>
						</Column>
					</DataTable>

					<br><br><br>

					<h5 v-if="aramaSonuclariData2.length > 0">Alternatif Yedek Parçalar</h5>
					<DataTable v-if="aramaSonuclariData2.length > 0" :value="aramaSonuclariData2" selectionMode="single" :rowHover="true" :scrollable="true" scrollHeight="500px" editMode="cell" @cell-edit-complete="aramaSonuclariOnCellEditComplete_Alternatif" @cell-edit-init="aramaSonuclariOnCellEditInit_Alternatif" responsiveLayout="scroll">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<Column field="yedekParcaKodu" header="Yedek Parça Kodu" style="flex: 0 0 11rem">
							<template #body="slotProps">
								<span v-if="slotProps.data.bulundu == false" class="durum-badge bulunamadi">{{ slotProps.data.yedekParcaKodu }} </span>
								<span v-else >{{ slotProps.data.yedekParcaKodu }}<br><span style="text-decoration: line-through;">{{ slotProps.data.eskiParcaKodu }}</span></span>
							</template>
						</Column>
						<Column field="yedekParcaAdi" header="Yedek Parça Adı">
							<template #body="slotProps">
								<span v-if="slotProps.data.bulundu == false" class="durum-badge bulunamadi">Bulunamadı!</span>
								<span v-else >{{ slotProps.data.yedekParcaAdi }}<br><span style="text-decoration: line-through;">{{ slotProps.data.eskiParcaAdi }}</span></span>
							</template>
						</Column>
						<Column field="uretici" header="Üretici" style="flex: 0 0 10rem">
							<template #body="slotProps">
								<span v-if="slotProps.data.bulundu == false" class="durum-badge bulunamadi">{{ slotProps.data.uretici }} </span>
								<span v-else >{{ slotProps.data.uretici }} </span>
							</template>
						</Column>
						<Column field="teslimSuresi" header="Teslim Süresi" style="flex: 0 0 15rem">
							<template #editor="{ data, field }">
								<InputText v-model="data[field]" type="search" inputStyle="width: 40px" @input="data[field] = $event.target.value.toUpperCase()" />
							</template>
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ slotProps.data.teslimSuresi }} </span>
								<span v-else >{{ slotProps.data.teslimSuresi }} </span>
							</template>
						</Column>
						<Column field="adet" header="Miktar" style="flex: 0 0 7rem; justify-content: center;">
							<template #editor="{ data, field }">
								<InputNumber v-model="data[field]" mode="decimal" showButtons :min="0" :max="1000" inputStyle="width: 40px" />
							</template>
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ formatNumber(slotProps.data.adet) }} </span>
								<span v-else >{{ formatNumber(slotProps.data.adet) }} </span>
							</template>
						</Column>
						<Column field="birimFiyat" header="Birim Fiyat" style="flex: 0 0 7rem; justify-content: right;">
							<template #editor="{ data, field }">
								<InputNumber v-model="data[field]" mode="decimal" :minFractionDigits="2" inputStyle="width: 80px" />
							</template>
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ formatNumber(slotProps.data.birimFiyat) }} {{ getCurrency2(slotProps.data.paraBirimiName) }}</span>
								<span v-else >{{ formatNumber(slotProps.data.birimFiyat) }} {{ getCurrency2(slotProps.data.paraBirimiName) }}</span>
							</template>
						</Column>
						<Column field="paraBirimiName" header="Para Birimi" bodyStyle="text-align: center" style="flex: 0 0 7rem; justify-content: center;">
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ slotProps.data.paraBirimiName }} </span>
								<span v-else >{{ slotProps.data.paraBirimiName }} </span>
							</template>
						</Column>
						<Column field="toplamFiyat" header="Sipariş Fiyatı" bodyStyle="text-align: right" style="flex: 0 0 7rem; justify-content: right;" >
							<template #body="slotProps">
								<span v-if="slotProps.data.adet > 0" class="durum-badge acik">{{ formatNumber(slotProps.data.toplamFiyat) }}  {{ getCurrency2(slotProps.data.paraBirimiName) }}</span>
								<span v-else >{{ formatNumber(slotProps.data.toplamFiyat) }}  {{ getCurrency2(slotProps.data.paraBirimiName) }}</span>
							</template>
						</Column>
					</DataTable>
					<div class="flex justify-content-between">
						<Button v-if="aramaSonuclariData.length > 0 && seciliKayitAdedi > 0" :label="olusturButtonLabel" class="mt-3 p-button-secondary" icon="pi pi-plus" @click="sipariseEkle(false)" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<Dialog header="Excel dosyasından Yedek Parça" v-model:visible="displayExcelImport" :showCloseIcon="true" :breakpoints="{'960px': '50vw'}" :style="{width: '90vw'}" :modal="true">
		<div class="p-col-12 p-md-12">
			<div class="p-fluid">
				<div class="p-formgrid p-grid">
					<div class="p-field p-col">
						<FileUpload ref="fileUpload" :customUpload="true" @uploader="fileUploader" :multiple="false" :maxFileSize="100000" :invalidFileSizeMessage="'{0}: dosya boyutu limitini aştınız, {1} daha küçük bir dosya yükleyiniz.'" :fileLimit="1" >
							<template #empty>
								<p>Yüklenecek dosyayı buraya taşıyınız</p>
							</template>
						</FileUpload>
					</div>
				</div>
			</div>
		</div>
	</Dialog>

	<Dialog header="Servis Raporundan Yedek Parça" v-model:visible="displayServisRaporuImport" :showCloseIcon="true" :breakpoints="{'960px': '50vw'}" :style="{width: '90vw'}" :modal="true" @show="showServisRaporuImport" >
		
		<div class="grid m-1">
			<div class="col-12">
				<div class="flex">
					<div style="width: 30%;">
						<div class="p-inputgroup">
							<InputText v-model="aranacakTextServisRaporlari" type="search" placeholder="Servis Kodu" v-on:keyup.enter="onClickListeleServisRaporlari" />
							<Button label="Listele" icon="pi pi-search" iconPos="right" class="p-button-secondary" @click="onClickListeleServisRaporlari" />
						</div>
						
					</div>
					<Button label="Yenile" icon="pi pi-refresh" iconPos="right" class="p-button-primary ml-3" @click="onClickYenileServisRaporlari" />
				</div>
			</div>
			
		</div>
		

		<div style="display: flex; flex-wrap: wrap;">
			<div v-for="(item, index) in yedekParcaliServisRaporlariFiltered" :key="index" style="flex-basis: 180px; display: flex; flex-direction: column; background-color: #f8f8f8; border: 1px solid #ddd; padding: 10px; border-radius: 10px;" class="servisraporu m-2">
				<div style="text-align: center; font-size: 14px; padding-top: 10px;">{{ item.tarih }}</div>
				<div style="text-align: center; font-size: 14px; font-weight: bold;">{{ item.servisKodu }}</div>
				<div style="text-align: center; font-size: 3rem; font-weight: bold; padding-top: 10px;">{{ item.yedekParcaAdedi }}</div>
				<Button label="Seç" class="p-button-raised p-button-rounded p-button-sm p-button-gray" @click="onClickServisRaporu(item.servisRaporuId)" />
			</div>
		</div>
	</Dialog>

</template>

<script>
import CrmService from "../service/CrmService";
import user from '../store/user';
import readXlsxFile from 'read-excel-file'

export default {
	props: {
        firmaId: {
            type: String,
            required: true
        }
    },
	data() {
		return {
			crmService: null,
			mainData: {},
			aranacakText: '',
			arananText: '',
			aramaSonuclariData: [],
			aramaSonuclariData2: [],

			aranacakTextServisRaporlari: '',
			servisRaporu: {},

			yedekParcaliServisRaporlari: [],
			yedekParcaliServisRaporlariFiltered: [],

			displayExcelImport: false,
			displayServisRaporuImport: false,

			servisRaporu_CrmDataTable_options: {
				searchAttributes: ["bm_kod", "bm_servistalebiidname", "bm_uniteidname", "subject"],
				filterParent: [
					{ 'name': 'bm_firmaid', 'filterOperator': 'eq', 'value': this.firmaId }
				],
			},
		}
	},
	async created() {
		if (this.profileData) {
			const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Y.P. Teklif / Sipariş');
			if (yetkisiVarmi == false) {
				this.$router.replace({ name: 'accessdenied' });
			}
		}

		this.crmService = new CrmService();

		console.log('created');

		let loader = this.$loading.show({
			container: this.$refs.smsContainer
		});

		debugger;
		if (this.entityId != null) {
			try {
				debugger;

				let data = await this.crmService.getYedekParcaTeklifSiparisById(this.entityId);
				if (data.jsonData.length > 0) {
					this.mainData = data.jsonData[0];
					this.teklifUrunleriData = data.teklifUrunData;
				}
			} catch (error) {
				console.log(error);
				//this.$router.push('/');
			}
			finally {
				loader.hide();
			}
		}
		else {
			//this.$router.push('/');
			loader.hide();
		}
	},
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData() {
			return this.$store.getters.getProfile;
		},
		olusturButtonLabel(){
			const filtered = this.aramaSonuclariData.filter( x => 
				x.adet > 0 && x.bulundu == true
			);

			const filtered2 = this.aramaSonuclariData2.filter( x => 
				x.adet > 0 && x.bulundu == true
			);

			const combinedList = [...filtered, ...filtered2];

			let sum = combinedList.reduce(function(acc, item) {
				return acc + item.toplamFiyat;
			}, 0);

			return "SİPARİŞE EKLE (" + combinedList.length + " seçili kayıt -|- Toplam Sipariş Fiyatı: " + this.formatNumber(sum) + ")";
		},
		olusturButtonLabelOnceSil(){
			const filtered = this.aramaSonuclariData.filter( x => 
				x.adet > 0 && x.bulundu == true
			);

			let sum = filtered.reduce(function(acc, item) {
				return acc + item.toplamFiyat;
			}, 0);

			return "VAROLANLARI SİL SONRA SİPARİŞE EKLE (" + filtered.length + " seçili kayıt -|- Toplam Sipariş Fiyatı: " + this.formatNumber(sum) + ")";
		},
		seciliKayitAdedi(){
			const filtered = this.aramaSonuclariData.filter( x => 
				x.adet > 0 && x.bulundu == true
			);

			const filtered2 = this.aramaSonuclariData2.filter( x => 
				x.adet > 0 && x.bulundu == true
			);

			const combinedList = [...filtered, ...filtered2];

			return combinedList.length;
		},
		SM_satisYontemi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_satisyontemi');
		},
		servisRaporuSelected: {
            get: function () {
                if (this.servisRaporu["servisRaporuId"]) {
                    return { "Value": this.servisRaporu["servisRaporuId"], "Name": this.servisRaporu["servisRaporuName"] }
                } else{ return null; }
            },
            set: function(newValue) {
                if (newValue == null) {
                    this.servisRaporu["servisRaporuId"] = null;
                    this.servisRaporu["servisRaporuName"] = null;
                }
                else {
                    this.servisRaporu["servisRaporuId"] = newValue.Value;
                    this.servisRaporu["servisRaporuName"] = newValue.Name;
                }
            }
        },
	},
	methods: {
		async sipariseEkle(eskileriSil) {
			debugger;
			try {
				const filtered = this.aramaSonuclariData.filter( x => 
					x.adet > 0 && x.bulundu == true
				);

				const filtered2 = this.aramaSonuclariData2.filter( x => 
					x.adet > 0 && x.bulundu == true
				);

				const combinedList = [...filtered, ...filtered2];

				let postData = {};
				postData["eskileriSil"] = eskileriSil;
				postData["yedekParcaTeklifSiparisId"] = this.entityId;
				postData["yedekParcaTeklifSiparis"] = combinedList;

				console.log(combinedList);
				debugger;

				const response = await this.crmService.getYedekParcaTeklifSiparisUrunleriCreate(postData);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
						this.$emit('modalKapansin');
						this.onClickListele();
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}
		},
		onClickListele(){
			if (this.aranacakText.length >= 3) {
				this.crmService.getTeklifGetYedekParca(this.aranacakText)
				.then(data => {
					this.aramaSonuclariData = data.jsonData;

					/*
					this.aramaSonuclariData2 = data.jsonData
						.filter(x => x.alternatifParcaKodu !== null && x.alternatifParcaKodu !== undefined && x.alternatifParcaKodu !== '')
						.map(x => JSON.parse(JSON.stringify(x)));
*/
					this.arananText = this.aranacakText;
				});

				this.crmService.getTeklifGetYedekParca_Alternatif(this.aranacakText)
				.then(data => {
					this.aramaSonuclariData2 = data.jsonData;
				});
			}
			else {
				this.$toast.add({severity:'error', summary: 'Uyarı', detail: 'Arama yapabilmek için en az 3 karakter yazınız.', life: 5000});
			}
		},
		onClickTemizle(){
			this.aramaSonuclariData = [];
			this.aramaSonuclariData2 = [];
			this.arananText = '';
			this.aranacakText = '';
		},
		onClickListeleServisRaporlari(){
			if (this.aranacakTextServisRaporlari == '') {
				this.yedekParcaliServisRaporlariFiltered = this.yedekParcaliServisRaporlari.map(a => ({...a}));
			}
			else {
				this.yedekParcaliServisRaporlariFiltered = this.yedekParcaliServisRaporlari.filter(o => {  return Object.keys(o).some(k => { if(typeof o[k] === 'string') return o[k].toLowerCase().includes(this.aranacakTextServisRaporlari.toLowerCase());       });      });
			}
		},
		onClickYenileServisRaporlari(){
			this.aranacakTextServisRaporlari = '';
			this.yedekParcaliServisRaporlariFiltered = this.yedekParcaliServisRaporlari.map(a => ({...a}));
		},
		async onClickServisRaporu(servisRaporuId){
			let loader = this.$loading.show({
                container: this.$refs.smsContainer
            });

			debugger;
			try {
				const response = await this.crmService.getServisRaporundanYedekParca(servisRaporuId);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'HATA: getServisRaporundanYedekParca', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.displayServisRaporuImport = false;
						this.aramaSonuclariData = response.jsonData;
						debugger;
						for (let index = 0; index < this.aramaSonuclariData.length; index++) {
							this.aramaSonuclariData[index].toplamFiyat = this.aramaSonuclariData[index].adet * this.aramaSonuclariData[index].birimFiyat;

							this.crmService.getTeklifGetYedekParca_Alternatif(this.aramaSonuclariData[index].yedekParcaKodu)
							.then(data => {
								data.jsonData.forEach(item => this.aramaSonuclariData2.push(item));
							});
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA: getServisRaporundanYedekParca - ', detail: error.message, life: 3500});
			}
			finally {
				loader.hide();
			}
		},
		async showServisRaporuImport(){
			console.log('showServisRaporuImport');
			let loader = this.$loading.show({
                container: this.$refs.smsContainer
            });

			debugger;
			try {
				const response = await this.crmService.getServisRaporlariYedekParcali(this.firmaId);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'HATA: showServisRaporuImport', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.yedekParcaliServisRaporlari = response.jsonData;
						this.yedekParcaliServisRaporlariFiltered = this.yedekParcaliServisRaporlari.map(a => ({...a}));
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA: showServisRaporuImport - ', detail: error.message, life: 3500});
			}
			finally {
				loader.hide();
			}
		},
		onClickYeniYedekParca(){
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';
			let routeData = this.$router.resolve({ name: 'bm_yedekparcanew' });
			window.open(routeData.href, '_blank', features);
		},
		aramaSonuclariOnCellEditComplete_Alternatif(event){
			debugger;
			if (event.field == "adet") {
				let fiyat = this.aramaSonuclariData2[event.index]["birimFiyat"]; 
				this.aramaSonuclariData2[event.index][event.field] = event.newValue;
				this.aramaSonuclariData2[event.index]["toplamFiyat"] = event.newValue * fiyat;
			}
			else if(event.field == "teslimSuresi"){
				this.aramaSonuclariData2[event.index][event.field] = event.newValue;
			}
			else if(event.field == "birimFiyat"){
				this.aramaSonuclariData2[event.index][event.field] = event.newValue;
				if (this.aramaSonuclariData2[event.index]["adet"] == 0) {
					this.aramaSonuclariData2[event.index]["adet"] = 1;
					let fiyat = event.newValue;
					this.aramaSonuclariData2[event.index]["toplamFiyat"] = fiyat;
				}
				else {
					const adet = this.aramaSonuclariData2[event.index]["adet"];
					let fiyat = event.newValue;
					this.aramaSonuclariData2[event.index]["toplamFiyat"] = adet * fiyat;
				}
			}
		},
		aramaSonuclariOnCellEditInit_Alternatif(event){
			if(event.field == "birimFiyat"){
				this.aramaSonuclariData2[event.index][event.field] = null;
			}
		},
		aramaSonuclariOnCellEditComplete(event){
			debugger;
			if (event.field == "adet") {
				let fiyat = this.aramaSonuclariData[event.index]["birimFiyat"]; 
				this.aramaSonuclariData[event.index][event.field] = event.newValue;
				this.aramaSonuclariData[event.index]["toplamFiyat"] = event.newValue * fiyat;
			}
			else if(event.field == "teslimSuresi"){
				this.aramaSonuclariData[event.index][event.field] = event.newValue;
			}
			else if(event.field == "birimFiyat"){
				this.aramaSonuclariData[event.index][event.field] = event.newValue;
				if (this.aramaSonuclariData[event.index]["adet"] == 0) {
					this.aramaSonuclariData[event.index]["adet"] = 1;
					let fiyat = event.newValue;
					this.aramaSonuclariData[event.index]["toplamFiyat"] = fiyat;
				}
				else {
					const adet = this.aramaSonuclariData[event.index]["adet"];
					let fiyat = event.newValue;
					this.aramaSonuclariData[event.index]["toplamFiyat"] = adet * fiyat;
				}
			}
		},
		aramaSonuclariOnCellEditInit(event){
			if(event.field == "birimFiyat"){
				this.aramaSonuclariData[event.index][event.field] = null;
			}
		},
		formatNumber(value) {
			if (value != undefined) {
				return value.toLocaleString('tr-TR');
			}
		},
		getCurrency() {
			let paraBirimi = '';
			switch (this.mainData.paraBirimiName) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;

				default:
					paraBirimi = "";
					break;
			}
			return paraBirimi;
		},
		getCurrency2(paraBirimiUzun) {
			let paraBirimi = '';
			switch (paraBirimiUzun) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;

				default:
					paraBirimi = "";
					break;
			}
			return paraBirimi;
		},
		async fileUploader(e){
			var file = e.files ? e.files[0] : null;

			const excelReadResponse = await readXlsxFile(file);
			debugger;
			for (let i = 1; i < excelReadResponse.length; i++) { 
				const row = excelReadResponse[i];
				const yedekParcaKodu = row[0];
				const adet = row[1];

				const item = {
					yedekParcaId: '',
					yedekParcaKodu,
					yedekParcaAdi: '',
					uretici: '',
					
					birimFiyat: 0,
					paraBirimiId: '',
					paraBirimiName: '',
					adet,
					toplamFiyat: 0,
					teslimSuresi: ''
				}

				this.aramaSonuclariData.push(item);
			}

			const yedekParcaKodlari = this.aramaSonuclariData.map(item => item.yedekParcaKodu).join("|");
			const response = await this.crmService.getYedekParcaSorgulaCoklu(yedekParcaKodlari);
			if (response) {
				if (response.hata == true) {
					this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
				}else {
					debugger;
					for (let index = 0; index < this.aramaSonuclariData.length; index++) {
						let aramaSonucItem = this.aramaSonuclariData[index];
						debugger;
						console.log(aramaSonucItem);
						let bulabildimiItem = response.jsonData.filter(x => x.yedekParcaKodu == aramaSonucItem.yedekParcaKodu);
						debugger;
						console.log(bulabildimiItem);
						if (bulabildimiItem.length > 0) {
							const tempAdet = aramaSonucItem.adet;
							debugger;
							this.aramaSonuclariData[index] = bulabildimiItem[0];
							this.aramaSonuclariData[index].adet = tempAdet;
							this.aramaSonuclariData[index].toplamFiyat = this.aramaSonuclariData[index].adet * this.aramaSonuclariData[index].birimFiyat;
							this.aramaSonuclariData[index]["bulundu"] = true;
							debugger;

							this.crmService.getTeklifGetYedekParca_Alternatif(bulabildimiItem[0].yedekParcaKodu)
							.then(data => {
								data.jsonData.forEach(item => this.aramaSonuclariData2.push(item));
							});

						} else {
							this.aramaSonuclariData[index]["bulundu"] = false;
						}
					}
				}
			}


			this.$refs.fileUpload.clear();
			this.displayExcelImport = false;
		},
	},
}
</script>

<style lang="scss" scoped>

	.durum-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		font-weight: bold;
		font-size: 13px;
		letter-spacing: .3px;

		&.acik {
			background: #FFCDD2;
			color: #C63737;
		}

		&.bulunamadi {
			background-color: #FBC02D;
			color: #212529;
		}
	}

	.p-button-gray {
		background-color: #aaaaaa;
	}

</style>
